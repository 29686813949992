import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/sections/footer';
import { useTranslation } from 'react-i18next';
import Lead from '../components/sections/lead';
import leadVideo from '../resources/video/homepage.mp4';
import policy1 from '../resources/policy/Единая_стратегия_в_области_устойчивого_развития_Акваарт.doc';
import policy2 from '../resources/policy/Кодекс_делового_поведения_и_этики_АКВААРТ.doc';
import policy3 from '../resources/policy/Кодекс_корпоративного_управления_Акваарт.doc';
import policy4 from '../resources/policy/Политика_в_области_внутреннего_аудита_Акваарт.doc';
import policy5 from '../resources/policy/Политика_в_области_контроля_за_контрагентами_Акваарт.doc';
import policy6 from '../resources/policy/Политика_в_области_соблюдения_прав_человека.doc';
import policy7 from '../resources/policy/Политика_в_области_управления_рисками_Акваарт.doc';
import policy8 from '../resources/policy/Политика_в_области_энергосбережения_и_повышения_энергоэффективности.doc';
import policy9 from '../resources/policy/Политика_по_охране_труда.doc';
import policy10 from '../resources/policy/Программа_зеленый_офис.doc';
import policy11 from '../resources/policy/Стратегия_управления_персоналом_Акваарт_+.doc';
import policy12 from '../resources/policy/Политика_управления_персоналом_Акваарт_+.doc';
import policy13 from '../resources/policy/Экологическая_стратегия.doc';
import policy14 from '../resources/policy/Сертификат_Эталон.PDF';
import policy15 from '../resources/policy/Лесной_эталон_Сертификат.PDF';
import policy16 from '../resources/policy/Экологическая_политика.doc';
import copyIcon from '../images/copy-icon.svg';

const policyList = [
	{
		title: 'Единая стратегия в области устойчивого развития Акваарт',
		href: policy1,
	},
	{
		title: 'Кодекс делового поведения и этики Акваарт',
		href: policy2,
	},
	{
		title: 'Кодекс корпоративного управления Акваарт',
		href: policy3,
	},
	{
		title: 'Политика в области внутреннего аудита Акваарт',
		href: policy4,
	},
	{
		title: 'Политика в области контроля за контрагентами Акваарт',
		href: policy5,
	},
	{
		title: 'Политика в области соблюдения прав человека',
		href: policy6,
	},
	{
		title: 'Политика в области управления рисками Акваарт',
		href: policy7,
	},
	{
		title:
			'Политика в области энергосбережения и повышения энергоэффективности',
		href: policy8,
	},
	{
		title: 'Политика по охране труда',
		href: policy9,
	},
	{
		title: 'Программа "зеленый офис"',
		href: policy10,
	},
	{
		title: 'Политика управления персоналом',
		href: policy12,
	},
	{
		title: 'Стратегия управления персоналом',
		href: policy11,
	},
	{
		title: 'Экологическая политика',
		href: policy16,
	},
	{
		title: 'Экологическая стратегия',
		href: policy13,
	},
	{
		title: 'Сертификат Эталон',
		href: policy14,
	},
	{
		title: 'Лесной эталон Сертификат',
		href: policy15,
	},
];

const Policy = () => {
	const { t } = useTranslation();

	const copyToClipboard = (file) => {
		navigator.clipboard.writeText(`${window.location.origin}${file}`);
	};

	return (
		<>
			<Helmet>
				<html lang="ru" />
				<title>{t('lead.seo.policy')}</title>
			</Helmet>{' '}
			<Lead leadVideo={leadVideo} title={t('lead.seo.policy')} />
			<section className="policy" id="second">
				<div className="policy__wrapper wrapper">
					<ul className="policy__list">
						{policyList.map((item) => (
							<li className="policy__item title-text">
								<a
									href={item.href}
									className="policy__link"
									download={item.title}
								>
									<span>{item.title}</span>
									<button
										data-tooltip="Скопировать ссылку"
										className="policy__button"
										onClick={(e) => {
											e.preventDefault();
											copyToClipboard(item.href);
										}}
									>
										<img src={copyIcon} alt="Копировать" />
									</button>
								</a>
							</li>
						))}
					</ul>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Policy;
